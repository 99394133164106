<template>
  <auth-layout>
    <page-section
      class="container mx-auto max-w-lg flex-1 flex flex-col items-center px-4"
    >
      <v-card class="text-center">
        <v-card-body>
          <h2 class="text-3xl font-bold">Autentificare</h2>
          <form
            class="mb-4"
            action=""
            method="POST"
            @submit.prevent="onSubmit"
            @keydown="form.errors.clear($event.target.name)"
          >
            <div class="mb-4" v-text="form.message"></div>
            <div class="text-left mb-4">
              <v-label for="email">Email </v-label>
              <v-input
                type="text"
                id="email"
                name="email"
                autocomplete="email"
                v-model="form.email"
                class="w-full"
              />
              <v-error :error="form.errors.get('email')" />
            </div>
            <div class="text-left mb-4">
              <v-label for="password">Parola </v-label>
              <v-input
                type="password"
                id="password"
                name="password"
                autocomplete="current-password"
                v-model="form.password"
                class="w-full"
              />
              <v-error :error="form.errors.get('password')" />
            </div>
            <v-button-primary :disabled="form.loading">
              <v-button-icon v-if="form.loading">
                <loader :radius="5" />
              </v-button-icon>
              Continua
            </v-button-primary>
          </form>

          <router-link :to="{ name: 'register' }">
            <span
              class="
                text-sm text-gray-500
                hover:text-gray-700
                border-b border-transparent
                hover:border-gray-700 hover:border-current
              "
            >
              Nu ai cont? Inregistreaza-te
            </span>
          </router-link>
        </v-card-body>
      </v-card>
    </page-section>
  </auth-layout>
</template>

<script>
import PageSection from "@/components/ui/PageSection.vue";
import Form from "@/core/Form";

import AuthLayout from "@/layout/AuthLayout.vue";
import authService from "@/services/authService";
import VLabel from "@/components/ui/VLabel.vue";
import VInput from "@/components/ui/VInput.vue";
import VError from "@/components/ui/VError.vue";
import VCard from "@/components/ui/VCard.vue";
import VCardBody from "@/components/ui/VCardBody.vue";
import VButtonPrimary from "@/components/ui/VButtonPrimary.vue";
import VButtonIcon from "@/components/ui/VButtonIcon.vue";
import Loader from "@/components/Loader.vue";

export default {
  components: {
    PageSection,
    AuthLayout,
    VLabel,
    VInput,
    VError,
    VCard,
    VCardBody,
    VButtonPrimary,
    VButtonIcon,
    Loader,
  },

  data() {
    return {
      form: new Form({
        email: "",
        password: "",
        device_name: "web",
      }),
    };
  },

  methods: {
    onSubmit() {
      this.form
        .submitService(authService, "login")
        .then(() => {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push({ name: "dashboard.show" });
          }
        })
        .catch((error) => {
          console.log("Error");

          this.message = error.message;
        });
    },
  },
};
</script>

<style></style>
